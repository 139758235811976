<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('staff.nfs_search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      >
        <template #facilityNames="scope">
          <div v-if="scope.props.row.facilityNames.split(',').length === 1">{{ scope.props.row.facilityNames }}</div>
          <template v-else>
            <div :id="`row_${scope.props.index}_f`">
              <div>{{ scope.props.row.facilityNames.split(',')[0] }}</div>
              <div class="text-sub">{{ $t('staff.login_nfc_multi_facilities') }}</div>
            </div>
            <b-tooltip :target="`row_${scope.props.index}_f`" placement="left">
              <div v-for="f in scope.props.row.facilityNames.split(',')" style="text-align:left">{{ f }}</div>
            </b-tooltip>
          </template>
        </template>
      </table-content>

    </template>

  </page-content>


</template>

<script>
import common from "@/common";
import nfcUtils from "@/views/staff/nfcUtils"
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";

import { VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Modal from "@/components/Modal";

export default {
  name: 'nfc',
  components: {
    Modal,
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      common,
      title: common.getMenuName('nfc'),
      view_permission: common.checkPermission('Nfc_Staff_Usage_Menu'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      show_reset_password: false,
      reset_password_data: {
        staffID: 0,
        newPassword: '',
        newPasswordAgain: ''
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'common.nfc_tag',
          field: 'serialNumber',
          width: '120px',
          changeHTML: (raw, _row) => {
            return `<span class="badge badge-light-secondary wh-font-family-monospace font-weight-normal wh-user-select-all">${nfcUtils.getConvertedNfc(raw)}</span>`
          }
        },
        {
          label: 'staff.nfc_type',
          field: 'nfcType',
          width: '110px',
          changeHTML: (raw, _row) => {
            let typeDesc = raw
            if (raw === 'Login') typeDesc = common.getI18n('staff.nfc_type_login')
            if (raw === 'CallBell') typeDesc = common.getI18n('staff.nfc_type_call_bell')
            return typeDesc
          }
        },
        {
          label: 'staff.staffCode',
          field: 'staffCode',
          width: '160px',
        },
        {
          label: 'staff.staff_name',
          field: 'staffName',
          width: '160px',
        },
        {
          label: 'staff.staff_status',
          field: 'isStaffActive',
          width: '110px',
          change: function (isActive) {
            return isActive ? common.getI18n('staff.active') : common.getI18n('staff.passive')
          }
        },
        {
          label: 'company.title',
          field: 'companyName',
          width: '200px',
        },
        {
          label: 'facility.title',
          field: 'facilityNames',
          width: '200px',
          // changeHTML: (raw, _row) => {
          //   const facilities = raw.split(',')
          //   return facilities.length > 1
          //     ? facilities[0] + `<br/><span class="text-sub">(${
          //         common.getI18n('staff.login_nfc_multi_facilities').replace('{{FIELD}}', facilities.length)
          //       })</span>`
          //     : facilities[0]
          // },
          type: 'slot',
          slot: 'facilityNames',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      header_actions: [],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Sta_View'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: true,
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: true,
        },
        {
          field: 'nfcType',
          label: 'staff.nfc_type',
          type: 'select',
          options: [
            { text: this.$t('staff.nfc_type_login'), value: 'Login' },
            { text: this.$t('staff.nfc_type_call_bell'), value: 'CallBell' },
          ]
        },
      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
      },
    }
  },
  activated() {
    if (!common.isAdmin()) {
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
      this.getList()
    }
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/staffnfc/GetNfcStaffUsageList'
      const search = this.getSearchCondition()

      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.items
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    editData: function (data) {
      this.$router.push({
        name: 'staff_edit',
        params: { id: common.encrypt(data.staffID) },
        query: { from: this.$route.fullPath }
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
  }
}
</script>
